<template>
  <div class="repairEvaluate" id="repairEvaluate">
    <div class="repairContentView">
      <div class="repairContentTitle">您对工程师到现场的响应速度满意吗？</div>
      <div class="repairContentRate">
        <van-rate
            v-model="baseData.rate1"
            :size="24"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
        />
      </div>

    </div>

    <div class="repairContentView">
      <div class="repairContentTitle">您对工程师技术水平和维修效率满意吗？</div>
      <div class="repairContentRate">
        <van-rate
            v-model="baseData.rate2"
            :size="24"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
        />
      </div>

    </div>

    <div class="repairContentView">
      <div class="repairContentTitle">您对工程师维修结果满意吗？</div>
      <div class="repairContentRate">
        <van-rate
            v-model="baseData.rate3"
            :size="24"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
        />
      </div>

    </div>

    <div class="repairContentView">
      <div class="repairContentTitle">评论</div>
      <el-input
          v-model="baseData.comment"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="师傅维修效率很高,效果很满意"
      >
      </el-input>
    </div>

    <div class="repairContentView">
      <div class="repairContentTitle">添加照片 <span style="color: #999999;font-weight: normal;font-size: 10px"
      >最多上传3张</span></div>
      <div class="repairImageList">
        <van-grid :border="false" :column-num="4" class="responseDescribeDetailImgList" :gutter="5">
          <van-grid-item v-for="(item, index) in baseData.photoList">
            <div style="display: flex;justify-content: center;flex-direction: column;">
              <van-image @click="showImageList(index)" :src="$replacePhoto(item.url)+'&scale=0.2'"/>
              <van-button style="margin-top: 5px" @click="cancelPhoto(index)" size="mini">取消</van-button>
            </div>
          </van-grid-item>
          <van-grid-item>
            <van-image @click="startPhoto" :src="addImage"/>
          </van-grid-item>
        </van-grid>
      </div>
    </div>

    <van-button class="sureButton" type="info" @click="submit">提交</van-button>

  </div>
</template>

<script>
import Vue from 'vue'
import { ImagePreview } from 'vant'

export default {
  name: 'repairEvaluate',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading('repairEvaluate')
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      }
    }
  },
  data() {
    return {
      viewType: 'device',
      loading: false,
      loadingView: null,
      addImage: require('@/icons/imgs/addImage.png'),
      baseData: {
        rate1: 5,
        rate2: 5,
        rate3: 5,
        comment: '',
        photoList: []
      }
    }
  },
  mounted() {
    // 配置微信jssdk
    if (typeof localStorage['corpInfo'] != 'undefined') {
      try {
        let corpInfo = JSON.parse(localStorage['corpInfo'])
        this.wxConfig(corpInfo)
      } catch (e) {

      }
    }
    let viewType = 'device'
    let query = this.$route.query
    if (this.$valueIsExist(query, 'viewType')) {
      viewType = query['viewType']
    }
    this.viewType = viewType
  },
  methods: {
    submit: function() {
      let getRequest = (post) => {
        return this.viewType == 'device' ? this.$api.deviceRepair
            .repairComment1(post) : this.$api.deviceRepair
            .repairComment2(post)
      }

      let startSubmit = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }

          let photo = ''
          for (let i = 0; i < this.baseData.photoList.length; i++) {
            let data = this.baseData.photoList[i]
            photo = photo + (photo == '' ? '' : ',') + data.id
          }

          let post = {
            comment: this.baseData.comment,
            features: '',
            id: id,
            photo: photo,
            rate1: this.baseData.rate1,
            rate2: this.baseData.rate2,
            rate3: this.baseData.rate3
          }

          getRequest(post).then(res => {
            resolve({ result: true })
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }

      this.$dialog.confirm({
        message: '是否提交该评价',
        beforeClose: (action, done) => {
          done()
          if (action != 'confirm') {
            return
          }
          startSubmit().then(res => {
            if (res.result) {
              localStorage.setItem('updateWorkerList', new Date().getTime())
              this.$toast.success('提交成功')
              this.$back();
            } else {
              this.$toast.fail('提交失败')
            }
          })
        }
      })
    },
    cancelPhoto: function(index) {
      this.baseData.photoList.splice(index, 1)
    },
    startPhoto: function() {
      if (this.baseData.photoList.length >= 3) {
        this.$toast.fail('最多上传3张照片')
        return
      }
      this.chooseImage().then(res => {
        if (res.result == false) {
          this.$toast.fail('请重新选择图片')
          return
        }
        this.saveImageToServer(res)
      })
    },
    saveImageToServer: function(res) {
      if (res.image == '') {
        this.$toast.fail('图片上传失败')
        return
      }

      let updateImageData = (res) => {
        if (this.$valueIsExist(res, 'id') == false) {
          this.$toast.fail('图片上传失败')
          return
        }
        let info = {
          id: res.id,
          remark: '',
          url: res.url
        }
        this.baseData.photoList.push(info)
      }

      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.loading = true
          this.uploadImageToWeChatAndGet(res.image, corpInfo).then(res => {
            if (res.result == false) {
              this.loading = false
              this.$toast.fail('图片上传失败')
              return
            }
            updateImageData(res.image)
            this.loading = false
          })
        } catch (e) {
          this.$toast.fail('图片上传失败')
        }
      }
    },
    showImageList: function(index) {
      let list = []
      for (let i = 0; i < this.baseData.photoList.length; i++) {
        let data = this.baseData.photoList[i]
        list.push(this.$replacePhoto(data.url))
      }
      ImagePreview(list, index)
    }
  }
}
</script>

<style lang="scss" scoped>

.repairEvaluate {
  width: 100%;
  height: 100%;
  padding: 10px 0px 0px 0px;

  .repairContentView {
    width: calc(100% - 20px);
    margin-left: 10px;
    padding: 5px 10px 5px 10px;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 10px;

    .repairContentTitle {
      width: 100%;
      height: 25px;
      line-height: 25px;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .repairContentRate {
      text-align: center;
      padding: 16px 0px 16px 0px;
    }

    .repairImageList {
      width: 100%;
    }

  }

  .sureButton {
    width: calc(100% - 20px);
    height: 40px;
    margin-left: 10px;
    border-radius: 20px;
    overflow: hidden;
  }

}

</style>